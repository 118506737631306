import React, { useState } from 'react';
import { Card, CardContent, Typography, CardActionArea } from '@mui/material';
import './ExperienceCard.css';

const ExperienceCard = ({ JobTitle, SubJobTitle, Company, description, tags, StartDate, EndDate, Link }) => {

    var [givenSubJobTitle] = useState('');
    givenSubJobTitle = SubJobTitle;

    const subJobTitleLines = givenSubJobTitle.split('\n');
    const descriptionLines = description.split('\n');

    return (
        <div className="experience-card-wrapper">
            <Card className="experience-card">
                <CardActionArea href={Link} target='_blank' rel='noreferrer'>
                    <CardContent>
                        <Typography className="experience-title" >
                            {JobTitle} &bull; <a className="experience-company-title" href={Link} >{Company} <span className='company-arrow'>&#10230;</span></a>
                        </Typography>
                        {subJobTitleLines.map((line, index) => (
                        <Typography key={index} className="experience-subtitle">{line}</Typography>
                        ))}
                        {descriptionLines.map((line, index) => (
                        <Typography key={index} className="experience-description" sx={{ marginTop: index === 0 ? 2 : 0 }}>
                            {line}
                        </Typography>
                        ))}
                        <Typography className="experience-date" sx={{marginTop: 8, textAlign: 'right'}}>
                            {StartDate} &#8212; {EndDate}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </div>
    );
};

export default ExperienceCard;