import React , {lazy,  Suspense} from 'react';
import { AppBar, Box, Container, Typography, Toolbar, Button } from '@mui/material'
import { Link } from 'react-router-dom';
import { FaXTwitter, FaGithub, FaLinkedin, FaInstagram, } from "react-icons/fa6";
import { IconContext } from "react-icons";
import projects from './../../data/projects.json';
import './ProjectLibrary.css';
const ProjectLibraryCard = lazy(() => import('./ProjectLibraryCard'));


function ProjectLibrary() {

    return (
        <> 
          <AppBar id="about" position="static" sx={{ background: '#0D1F2D', boxShadow: 3, }}>
            <Toolbar disableGutters sx={{ position: 'fixed', top: 0, right: 50, justifyContent: 'flex-end', paddingY: 1  }}>
              <Box className="appbarbox" sx={{ display: 'flex', gap: 2, background: 'transparent', paddingX: 3, paddingY: 0.5, flexGrow: 1, justifyContent: 'right' }}>
                <IconContext.Provider value={{ className: "social-icon", size: 25 }}>
                  <a href="https://github.com/Izesik" target="_blank" rel="noopener noreferrer"><FaGithub  /></a>
                  <a href="https://www.linkedin.com/in/isaac-nunez-8816a4234/" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
                  <a href="https://www.instagram.com/_isaacnunezz/" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
                  <a href="https://twitter.com/Izesik" target="_blank" rel="noopener noreferrer"><FaXTwitter /></a>
                </IconContext.Provider>
              </Box>
            </Toolbar>
          <Container>
            <Toolbar disableGutters sx={{ justifyContent: 'center', paddingY: 1 }}>
              <Box sx={{ display: 'flex', gap: 2, boxShadow: '0px 14px 16px -3px rgba(0,0,0,0.2)', border: '2px solid rgba(54, 122, 191, 0.56)', borderRadius: '50px', background: 'rgba(255,255,255,0.1)', paddingX: 1, paddingY: 0.5}}>
                <a href={'#about'}><Button color="inherit" sx={{ color: 'white', borderRadius: '30px', fontFamily: 'Overpass, sans serif', display: 'flex', alignItems: 'center', justifycontent: 'center', paddingTop: '10px' }}>About</Button></a>
                <a href={'#experience'}><Button color="inherit" sx={{ color: 'white', borderRadius: '30px', fontFamily: 'Overpass, sans serif', display: 'flex', alignItems: 'center', justifycontent: 'center', paddingTop: '10px' }}>Experience</Button></a>
                <a href={'#portfolio'}><Button color="inherit" sx={{ color: 'white', borderRadius: '30px', fontFamily: 'Overpass, sans serif', display: 'flex', alignItems: 'center', justifycontent: 'center', paddingTop: '10px'}}>Portfolio</Button></a>
                <a href={'#contact'}><Button color="inherit" sx={{ color: 'white', borderRadius: '30px', fontFamily: 'Overpass, sans serif', display: 'flex', alignItems: 'center', justifycontent: 'center', paddingTop: '10px'}}>Contact</Button></a>
              </Box>
            </Toolbar>
            <Toolbar disableGutters sx={{ justifyContent: 'center' }}>
            <Box className="appbarbox-homepage" sx={{ display: 'flex', gap: 2, background: 'transparent', paddingX: 3, paddingY: 0.5, flexGrow: 1, justifyContent: 'center' }}>
              <IconContext.Provider value={{ className: "social-icon-homepage", size: 25 }}>
                <a href="https://github.com/Izesik" target="_blank" rel="noopener noreferrer"><FaGithub  /></a>
                <a href="https://www.linkedin.com/in/isaac-nunez-8816a4234/" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
                <a href="https://www.instagram.com/_isaacnunezz/" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
                <a href="https://twitter.com/Izesik" target="_blank" rel="noopener noreferrer"><FaXTwitter /></a>
              </IconContext.Provider>
            </Box>
          </Toolbar>
          </Container>
        </AppBar>
         <Box  sx={{ textAlign: 'center', color: 'white', background: 'navy', backgroundImage: 'linear-gradient(180deg, #0D1F2D, #090E10)'}}>
         <Container id="projects"sx={{ paddingBottom: '125px'}}> 
         <Link to={`/#about`}><button className='back-button' style={{display: 'flex', alignItems: 'last baseline'}}><span className='back-arrow'style={{display: 'flex', alignItems: 'center'}}>&#10229;</span>Go Back </button></Link>
         <Typography variant="h3" sx={{ marginBottom: '40px', color: '#94A6B8', fontFamily: 'Overpass, sans serif', fontWeight: 'bold', textAlign: 'left'}}>
          Project Library
        </Typography>
         <Box className="grid-container" sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden'}}>
          <Suspense fallback={<div>Loading...</div>}>
          {projects.map((project, index) => (
            <ProjectLibraryCard
              key={index}
              title={project.title}
              description={project.description}
              tags={project.tags}
              year={project.year}
              status={project.status}
              link={project.link}
              linkName={project.linkName}
              imageUrl={project.imageUrl}
              
            />
          ))}
          </Suspense>
        </Box>
          </Container>
         </Box>
         </>
      );
}
export default ProjectLibrary;